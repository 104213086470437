@mixin xSmallMobile {
  @media only screen and (min-device-width: 0px) and (max-device-width: 360px) and (orientation: portrait) {
    @content;
  }
}
@mixin smallMobile {
  @media only screen and (min-device-width: 0px) and (max-device-width: 500px) {
    @content;
  }
}

@mixin mobile {
  @media (min-width: 0px) and (max-width: 767px) {
    @content;
  }
}

@mixin mobileTablet {
  @media (min-width: 0px) and (max-width: 960px) {
    @content;
  }
}

@mixin mobile-landscape {
  @media (min-width: 0px) and (max-width: 960px) and (orientation: landscape) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    @content;
  }
}

@mixin tablet-landscape {
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    @content;
  }
}

@mixin unexpected-screen-resposnive {
  @media (min-width: 830px) and (max-width: 960px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1280px) {
    @content;
  }
}
@mixin xSmallDesktop {
  @media (min-width: 770px) and (max-width: 1023px) {
    @content;
  }
}
@mixin smallDesktop {
  @media (min-width: 1024px) and (max-width: 1280px) {
    @content;
  }
}

@mixin mediumDesktop {
  @media (min-width: 1024px) and (max-width: 1366px) {
    @content;
  }
}

@mixin for-mobile {
  @media (min-width: 0px) and (max-width: 767px) {
    @content;
  }
}
@mixin for-desktop {
  @media (min-width: 1025px) {
    @content;
  }
}

@mixin for-tablet {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

// =============================================================================
// @Mixin
// =============================================================================
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}
@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;
  $extmods: (
    eot: "?",
    svg: "#" + str-replace($name, " ", "_"),
  );
  $formats: (
    otf: "opentype",
    ttf: "truetype",
  );
  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }
  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

@mixin fontsize($size) {
  // font-size: $size * 1px;
  font-size: $size * 0.1rem;
}

@mixin fontsize_imp($size) {
  // font-size: $size * 1px !important;
  font-size: $size * 0.1rem !important;
}

@mixin linearGradient($top, $bottom) {
  background: $top; /* Old browsers */
  background: -moz-linear-gradient(top, $top 0%, $bottom 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(20%, $top),
    color-stop(100%, $bottom)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, $top 20%, $bottom 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, $top 20%, $bottom 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, $top 20%, $bottom 100%); /* IE10+ */
  background: linear-gradient(to bottom, $top 20%, $bottom 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

%clearfix {
  *zoom: 1;
  &:after {
    content: "";
    display: table;
    line-height: 0;
    clear: both;
  }
}

@mixin padding($top, $right, $bottom, $left) {
  padding-top: $top;
  padding-right: $right;
  padding-bottom: $bottom;
  padding-left: $left;
}

@mixin margin($top, $right, $bottom, $left) {
  margin-top: $top;
  margin-right: $right;
  margin-bottom: $bottom;
  margin-left: $left;
}

@mixin themebutton {
  background: $themecolor;
  font-family: $themefont-Regular;
  color: $whitecolor;
  text-transform: uppercase;
  @include padding(5px, 18px, 5px, 18px);
  @include fontsize(22);
  display: inline-block;
  text-decoration: none;
  border: 0px;
  &:hover {
    background: darken($basiccolor, 8%);
    transition: all 0.6s ease;
  }
  &:active {
    background: darken($basiccolor, 25%);
    color: $whitecolor;
  }
}

@mixin rounded($radius: 0.5em) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

// Flexbox display
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

// Inline Flexbox display
@mixin flexboxInline() {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-box;
  display: inline-flex;
}

// The 'flex' shorthand
// - applies to: flex items
// <positive-number>, initial, auto, or none
@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

// Flex Flow Direction
// - applies to: flex containers
// row | row-reverse | column | column-reverse
@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

// Flex Line Wrapping
// - applies to: flex containers
// nowrap | wrap | wrap-reverse
@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

// Flex Direction and Wrap
// - applies to: flex containers
// <flex-direction> || <flex-wrap>
@mixin flex-flow($flow) {
  -webkit-flex-flow: $flow;
  -moz-flex-flow: $flow;
  -ms-flex-flow: $flow;
  flex-flow: $flow;
}

// Display Order
// - applies to: flex items
// <integer>
@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

// Flex grow factor
// - applies to: flex items
// <number>
@mixin flex-grow($grow) {
  -webkit-flex-grow: $grow;
  -moz-flex-grow: $grow;
  -ms-flex-grow: $grow;
  flex-grow: $grow;
}

// Flex shrink
// - applies to: flex item shrink factor
// <number>
@mixin flex-shrink($shrink) {
  -webkit-flex-shrink: $shrink;
  -moz-flex-shrink: $shrink;
  -ms-flex-shrink: $shrink;
  flex-shrink: $shrink;
}

// Flex basis
// - the initial main size of the flex item
// - applies to: flex itemsnitial main size of the flex item
// <width>
@mixin flex-basis($width) {
  -webkit-flex-basis: $width;
  -moz-flex-basis: $width;
  -ms-flex-basis: $width;
  flex-basis: $width;
}

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around
@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-flex-pack: $justify;
}

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch
@mixin align-content($align) {
  -webkit-align-content: $align;
  -moz-align-content: $align;
  -ms-align-content: $align;
  align-content: $align;
}

// Cross-axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | baseline | stretch
@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  align-items: $align;
}

// Cross-axis Alignment
// - applies to: flex items
// auto | flex-start | flex-end | center | baseline | stretch
@mixin align-self($align) {
  -webkit-align-self: $align;
  -moz-align-self: $align;
  -ms-align-self: $align;
  align-self: $align;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin boxshadow($h: 0px, $v: 2px, $b: 3px, $brand-color: rgba(0, 0, 0, 0.25)) {
  -webkit-box-shadow: $h $v $b $brand-color;
  -moz-box-shadow: $h $v $b $brand-color;
  box-shadow: $h $v $b $brand-color;
}

@mixin disable-selection($value) {
  -webkit-user-select: $value;
  -moz-user-select: $value;
  -ms-user-select: $value;
  user-select: $value;
}

@mixin button-bg($bg) {
  background: $bg;
  &:hover {
    background: darken($bg, 8%);
    transition: all 0.2s ease;
  }
  &:active {
    background: darken($bg, 15%);
  }
}

@mixin button-border($border) {
  border-color: $border;
  &:hover {
    border-color: darken($border, 25%);
    transition: all 0.2s ease;
  }
  &:active {
    border-color: darken($border, 45%);
  }
}

@mixin centerChildren($child) {
  text-align: center;
  &:before {
    content: "\200B";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  .#{$child} {
    display: inline-block;
    vertical-align: middle;
  }
}

// Placeholder Mixin
// ====================================
$pseudo-placeholder: "::-webkit-input-placeholder" "::-moz-placeholder" ":-ms-input-placeholder" "::placeholder";
@mixin placeholder-theme($placeholder-styles) {
  @each $pseudo in $pseudo-placeholder {
    @at-root #{&}#{$pseudo} {
      @each $key, $value in $placeholder-styles {
        #{$key}: #{$value};
      }
    }
  }
}
@mixin placeholder {
  @each $pseudo in $pseudo-placeholder {
    @at-root #{&}#{$pseudo} {
      @content;
    }
  }
}

@mixin opacity($opacity: 0.5) {
  filter: alpha(opacity=$opacity * 100);
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=#{$opacity*100});
  opacity: $opacity;
}

@function get-spacing($keys...) {
  $list: ();

  @for $i from 1 through length($keys) {
    $key: nth($keys, $i);

    @if map-has-key($global-spacing, $key) {
      @if length($keys) == 1 {
        $list: map-get($global-spacing, $key) * 1px;
      } @else {
        $list: append($list, map-get($global-spacing, $key) * 1px, space);
      }
    } @else {
      @error '#{$key} does not exist in the $global-spacing map.  Allowed values are #{$global-spacing}.';
      @return null;
    }
  }

  @return $list;
}

// margin and padding values array
$space-values: (0, 3, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, auto) !default;

// margin and padding shorthands
$space-prefixes: (
  p: padding,
  pt: padding-top,
  pr: padding-right,
  pb: padding-bottom,
  pl: padding-left,
  m: margin,
  mt: margin-top,
  mr: margin-right,
  mb: margin-bottom,
  ml: margin-left,
  ml-auto: margin-left,
) !default;

@mixin make-spaces() {
  @each $attr-short, $attr-long in $space-prefixes {
    @each $value in $space-values {
      .#{$attr-short}-#{$value} {
        @if $value == "auto" {
          /*add the @if loop*/
          #{$attr-long}: #{$value};
        } @else {
          #{$attr-long}: #{$value}#{"px"};
        }
      }
    }
  }
}

@include make-spaces();
