// =============================================================================
// Variables
// =============================================================================
$themefont-path: "assets/fonts/";
$themeimage-path: "./assets/images/";
$themefont-path-deap: "../../assets/fonts/";
$themeimage-path-deap: "../../assets/images/";
$themefont-Medium: NotoSans-Medium;
$themefont-Bold: NotoSans-Bold;
$themefont-ExtraBold: NotoSans-Regular-ExtraBold;
$themefont-Regular: NotoSans-Regular;
$themefont-SemiBold: NotoSans-SemiBold;
$themefont-Black: NotoSans-Black;
$themefont-arial: Arial, Helvetica, sans-serif;
// $theme-colors: (
//   primaryBlue: #C5209D,
//   primaryWhite: #ffffff,
//   primaryBlack: #1b2230,
//   primaryGreen: #75BF43,
//   secondaryGray: #f8fafc,
//   secondaryBlue: #6fb929,
//   primaryOrange: #C5209D,
//   tertiaryBlue: #0077a0,
//   primaryGray: #6E6E6E,
//   fourthBlue: #1f3c8b,
//   successColor: #1fcf37,
//   dangerColor: #ff0000,
// );

$client-primary: var(--client-primary);
$client-secondary: var(--client-secondary);
$client-tertiary: var(--client-tertiary);
$client-quaternary: var(--client-quaternary);
:root{
   --client-primary: #002979;
   --client-secondary: #019de1;
   --client-tertiary: #009690;
   --client-quaternary: #627798;

}
$theme-colors: (
  primaryBlue: #002979,
  primaryWhite: #ffffff,
  primaryBlack: #1b2230,
  primaryGreen: #009690,
  secondaryGray: #f8fafc,
  secondaryBlue: #019de1,
  primaryOrange: #ffbe00,
  tertiaryBlue: #0077a0,
  primaryGray: #627798,
  fourthBlue: #1f3c8b,
  successColor: #1fcf37,
  dangerColor: #ff0000,
);
@function getColor($color, $variant: null) {
  $color: map-get($theme-colors, $color);
  @return map-get(
      (
        // extra-light: mix($color, white, 25%),
        // light:       mix($color, white, 50%),
        // semi-light:  mix($color, white, 75%),
        // semi-dark:   mix($color, black, 75%),
        // dark:        mix($color, black, 50%),
        // extra-dark:  mix($color, black, 25%)
      ),
      $variant
    )
    or $color;
}

$container-max-widths: (
  md: 720px,
  lg: 960px,
  xl: 1280px,
);

// $themeBorderRadius: 5px;
@import "~bootstrap/scss/bootstrap";
